export const environment = {
    production: false,
    hmr: true,
    // baseUrl:'http://192.168.20.67:8000',
    // baseUrl:'http://192.168.20.129:8000', // harendra pc
    // baseUrl:'http://192.168.20.59:8000', // smile pc
    baseUrl:'http://ikooapidev.elitetraveltech.in',
    defaultLanguage: 'Label',
    supportedLanguages: ['Label', 'TMSA'],
};
